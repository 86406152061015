<template>
    <table-view :total="total" :query-info.sync="queryInfo">
        <template #header>
            <div class="header-button mb-1">
                <el-button @click="showDialog()" v-permission="['achievement:subject:add']" type="success" size="small">
                    诊改信息导出
                </el-button>
                <el-button @click="showDialog()" type="primary" size="small">
                    增加诊改信息
                </el-button>
            </div>
        </template>
        <el-table :data="tableData" class=" mt-1" style="width: 100%" border stripe>
            <el-table-column prop="gradeName" label="学年"></el-table-column>
            <el-table-column prop="term" label="学期">
                <template v-slot="{ row }">
                    {{ row.term ? `第${row.term}学期` : '' }}
                </template>
            </el-table-column>
            <el-table-column prop="currentWorkSummary" label="本学期工作总结"></el-table-column>
            <el-table-column prop="currentSignificantEvents" label="本学期大事记"></el-table-column>
            <el-table-column prop="nextWorkPlan" label="下学期工作计划"></el-table-column>
            <el-table-column prop="selfDiagnosisOpinions" label="自我诊断意见"></el-table-column>
            <el-table-column prop="improvement" width="150" label="改进措施及成效"></el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
                <template v-slot="{ row }">
                    <el-button type="text" @click="showDialog(row.id)">
                        编辑
                    </el-button>
                    <el-button type="text"
                        @click="del(delDeptCorrectByUserId, row.id, `${row.gradeName} 第${row.term}学期 诊改信息`)">
                        删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <dialog-add-rectificaition ref='dialogRef' :visible.sync='visibleDialog' v-if='visibleDialog'
            @on-close='renderTable' />
    </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'

import { delDeptCorrectByUserId, pageDeptCorrectByCurUser } from '@/api/infoCollect/DeptCorrectInfo-api.js'
import DialogAddRectificaition from './components/DialogAddRectificaition.vue'

export default {
    components: { DialogAddRectificaition },
    mixins: [tableView],
    data() {
        return {
            formData: {
                id: '',
                userId: '',
                realName: '',
                deptName: '',
                teacherNo: '',
                gradeName: '',
                term: '',
                currentWorkSummary: '',
                currentSignificantEvents: '',
                nextWorkPlan: '',
                selfDiagnosisOpinions: '',
                improvement: ''
            }
        }
    },
    mounted() {
        this.renderTable()
    },
    methods: {
        delDeptCorrectByUserId,
        async renderTable(pageNum) {
            await this.getTableData(pageDeptCorrectByCurUser, pageNum)
        }
    }
}
</script>

<style lang="scss" scoped>
.successNum {
    margin-right: 20px;
}
</style>
